import React, {useEffect, useState} from 'react';
import {CheckIcon, XMarkIcon} from '@heroicons/react/20/solid';
import {MiniSpinner} from 'components/atoms/Spinner';
import NumberFormat, {NumberFormatValues} from 'react-number-format';
import {classNames} from 'lib/styles';
import {FormErrorMessage} from '../../components/FormErrorMessage';
import {getBankByCode} from 'features/bank/graphql/actions';
import {useDebouncedCallback} from 'use-debounce';
import {getFormattedBsb, getUnformattedBsb} from 'payble-shared';

type Bank = {
  bsb: string;
  name: string;
  icon?: any;
};

type DirectDebitValues = {
  accountNumber: string;
  bsbNumber: string;
  bank: Bank | null;
};

type Props = {
  disabled: boolean;
  onChange: (value: DirectDebitValues) => void;
  onValid: (value: boolean) => void;
  value: DirectDebitValues;
  touched: {
    accountNumber?: boolean;
    bsbNumber?: boolean;
  };
  errors: {
    accountNumber?: string;
    bsbNumber?: string;
  };
};

export const DirectDebitForm: React.FC<Props> = ({
  disabled,
  onChange,
  onValid,
  value,
  touched,
  errors,
}) => {
  const [bsbStatus, setBsbStatus] = useState<
    'idle' | 'loading' | 'incorrect' | 'valid'
  >('idle');

  useEffect(() => {
    if (
      ['valid'].includes(bsbStatus) &&
      Object.values(errors).filter(Boolean).length === 0
    ) {
      onValid(true);
    } else {
      onValid(false);
    }
  }, [bsbStatus, errors]);

  const validateBank = useDebouncedCallback(async safeBsb => {
    setBsbStatus('loading');

    if (safeBsb.length === 6) {
      const code = getFormattedBsb(safeBsb);
      const bank = await getBankByCode(code, 'au');

      if (bank) {
        onChange({
          ...value,
          bsbNumber: safeBsb,
          bank: {
            bsb: bank.code,
            name: bank.name,
          },
        });
        setBsbStatus('valid');
      } else {
        setBsbStatus('incorrect');
      }
    } else {
      setBsbStatus('idle');
    }
  }, 500);

  const onBsbChange = (values: NumberFormatValues) => {
    const safeBsb = getUnformattedBsb(values.formattedValue);

    if (safeBsb.length > 6) {
      return;
    }

    onChange({
      ...value,
      bsbNumber: safeBsb,
      bank: null,
    });

    validateBank(safeBsb);
  };

  const hasValidAccountNumber =
    value.accountNumber.length > 0 &&
    value.accountNumber.replaceAll('-', '').length <= 9;

  return (
    <>
      <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
        <div>
          <label
            htmlFor="bsb"
            className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
          >
            BSB number
          </label>
        </div>
        <div className="sm:col-span-2">
          <NumberFormat
            format="###-###"
            placeholder="000-000"
            name="bsb"
            id="bsb"
            className={classNames(
              'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
            )}
            disabled={disabled}
            allowLeadingZeros={true}
            defaultValue={value.bsbNumber}
            onValueChange={(values: NumberFormatValues) => onBsbChange(values)}
          />
          <div className="absolute mt-0">
            {bsbStatus === 'loading' && <MiniSpinner />}
            {bsbStatus === 'incorrect' && (
              <div className="flex items-center">
                <XMarkIcon className="w-4 h-4 text-red-500" />
                <span className="ml-1 text-sm text-gray-400">
                  Unknown BSB number
                </span>
              </div>
            )}

            {bsbStatus === 'valid' && (
              <div className="flex items-center">
                <CheckIcon className="w-4 h-4 text-green-500" />
                <span className="ml-1 text-sm text-gray-400">
                  {value.bank?.name}
                </span>
                {value.bank?.icon && (
                  <img src={value.bank.icon} className="w-4 ml-1" />
                )}
              </div>
            )}

            {errors.bsbNumber &&
              touched.bsbNumber &&
              value.bsbNumber.length === 0 &&
              ['idle', 'valid', 'incorrect'].includes(bsbStatus) && (
                <FormErrorMessage message={errors.bsbNumber} />
              )}
          </div>
        </div>
      </div>
      <div className="px-4 space-y-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
        <div>
          <label
            htmlFor="accountNumber"
            className="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
          >
            Bank account number
          </label>
        </div>
        <div className="sm:col-span-2">
          <NumberFormat
            format="#########"
            placeholder="000000000"
            name="accountNumber"
            id="accountNumber"
            className={classNames(
              'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6'
            )}
            autoComplete={'off'}
            disabled={disabled}
            defaultValue={value.accountNumber}
            allowLeadingZeros={true}
            onValueChange={(e: NumberFormatValues) =>
              onChange({
                ...value,
                accountNumber: e.value,
              })
            }
          />

          {value.accountNumber && !hasValidAccountNumber && (
            <div className="flex items-center">
              <XMarkIcon className="w-4 h-4 text-red-500" />
              <span className="mt-1 ml-1 text-sm text-gray-400">
                Account number must be less than 10 digits
              </span>
            </div>
          )}

          {errors.accountNumber && touched.accountNumber && (
            <FormErrorMessage message={errors.accountNumber} />
          )}
        </div>
      </div>
    </>
  );
};
