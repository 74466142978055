import React from 'react';
import {Notification} from '../../../lib/graphql/API';
import {PopOverSidebar} from 'components/organisms/PopOverSidebar';
import {FormHeader} from 'features/audience/forms/components/FormHeader';
import {SmsPreview} from 'features/audience/forms/components/SmsPreview';
import {DateTime} from 'luxon';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  notification: Notification;
}

export const NotificationPreview: React.FC<Props> = ({
  isOpen,
  onClose,
  notification,
}) => {
  return (
    <PopOverSidebar
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
        <div className="flex-1">
          <FormHeader
            setOpen={onClose}
            title="Notification sent"
            description={`Sent at ${DateTime.fromISO(
              notification.sentAt ?? ''
            ).toFormat('dd MMM yyyy hh:mm a')}`}
          />

          <SmsPreview
            messages={
              notification.preview
                ? [
                    {
                      key: notification.id,
                      text: [notification.preview],
                      sent: false,
                    },
                  ]
                : []
            }
          />
        </div>
      </div>
    </PopOverSidebar>
  );
};
