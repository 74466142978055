import React from 'react';
import {Notification, NotificationChannel} from 'lib/graphql/API';
import {DateTime} from 'luxon';
import {toTitleCase} from 'payble-shared';
import {NotificationPreview} from './NotificationPreview';
import {useDisclosure} from '../../../lib/hooks/useDisclosure';

type NotificationRowProps = {
  notification: Notification;
};

export const NotificationRow: React.FC<NotificationRowProps> = ({
  notification,
}) => {
  const preview = useDisclosure();

  const statusColor = (status: string) => {
    switch (status) {
      case 'sent':
        return 'bg-blue-100 text-blue-800';
      case 'failed':
        return 'bg-red-100 text-red-800';
      case 'delivered':
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  return (
    <>
      <NotificationPreview
        isOpen={preview.isOpen}
        onClose={() => preview.onClose()}
        notification={notification}
      />
      <tr
        className={
          notification.channel === NotificationChannel.Sms
            ? 'hover:bg-gray-100 cursor-pointer'
            : ''
        }
        onClick={() => {
          if (notification.channel !== NotificationChannel.Sms) {
            return;
          }
          preview.onOpen();
        }}
      >
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {notification.channel}
        </td>
        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
          {notification.name}
        </td>
        <td className="py-4 whitespace-nowrap justify-center">
          <div className="px-6 whitespace-nowrap">
            <span
              className={`px-4 py-2 inline-flex text-xs leading-5 font-semibold rounded-full ${statusColor(
                notification.status
              )}`}
            >
              {toTitleCase(notification.status)}
            </span>
          </div>
        </td>
        <td className="py-4 whitespace-nowrap justify-center">
          <div className="px-6 whitespace-nowrap">
            <div className="text-gray-500 text-xs font-semibold mt-2">
              Sent At
            </div>
            <div className="text-sm">
              {DateTime.fromISO(notification.sentAt ?? '').toFormat(
                'dd MMM yyyy hh:mm a'
              )}
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};
