import {NotificationSettingTable} from 'payble-app-shared';

import {useDisclosure} from 'lib/hooks/useDisclosure';
import {useAPIMutation, useAPIQuery} from 'lib/api';
import {PopOverSidebar} from 'components/organisms/PopOverSidebar';
import {FormHeader} from '../forms/components/FormHeader';

export const NotificationSettingTableForm = ({
  contactId,
  disclosure: {isOpen, onClose},
}: {
  contactId: string;
  disclosure: ReturnType<typeof useDisclosure>;
}) => {
  const {mutateAsync: update, error: updateError} = useAPIMutation(
    'updateNotificationSettings'
  );
  const {
    data: defaultValues,
    dataUpdatedAt,
    isPending,
  } = useAPIQuery('getNotificationSettings', {data: {contactId}});

  return (
    <PopOverSidebar
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >
      <div className="h-full overflow-y-scroll bg-white shadow-xl">
        <FormHeader
          setOpen={onClose}
          title="Manage notifications"
          description="Manage notification settings for this contact."
        />
        <div className="px-4 py-6">
          <NotificationSettingTable
            defaultValues={defaultValues}
            error={updateError}
            key={dataUpdatedAt}
            disabled={isPending}
            onSave={groups =>
              update({
                contactId,
                groups,
              })
            }
          />
        </div>
      </div>
    </PopOverSidebar>
  );
};
