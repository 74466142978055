import {useAPIMutation} from 'lib/api';
import {auth} from 'lib/index';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  useToast,
} from 'payble-ui';
import {useState} from 'react';

const stage = import.meta.env.VITE_STAGE;

export const StageResetButton = () => {
  const {billerName} = auth.useCurrentUser();
  const [confirming, setConfirming] = useState(false);
  const [enabled, setEnable] = useState(false);
  const {toast} = useToast();

  const answer = `${stage} - ${billerName}`;

  const {mutate: resetBiller, isPending} = useAPIMutation('resetBiller', {
    query: {
      onSuccess: () => {
        setConfirming(false);
        toast({
          title: 'Success',
          description: `Successfully reset ${stage} - ${billerName}`,
        });
      },
      onError: () => {
        toast({
          title: 'Error',
          description: `Failed to reset ${stage} - ${billerName}`,
        });
      },
    },
  });
  return (
    <>
      <Dialog
        open={confirming}
        onOpenChange={open => {
          if (!open) setConfirming(false);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Are you absolutely sure?</DialogTitle>
            <DialogDescription>
              <div className="py-8">
                This will erase all data for {answer}.
                <div>
                  To confirm, type <strong>{answer}</strong> in the box below
                </div>
                <input
                  onChange={e => {
                    setEnable(e.target.value === answer);
                  }}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                />
              </div>
            </DialogDescription>
            <DialogFooter>
              <Button
                type="button"
                variant="outline"
                onClick={() => setConfirming(false)}
                disabled={isPending}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="destructive"
                disabled={isPending || !enabled}
                isLoading={isPending}
                onClick={() => {
                  resetBiller(null);
                }}
              >
                Confirm reset
              </Button>
            </DialogFooter>
          </DialogHeader>
        </DialogContent>
      </Dialog>
      <Button
        variant="destructive"
        onClick={() => {
          setConfirming(true);
        }}
      >
        Reset
      </Button>
    </>
  );
};
